import { HttpClient } from '@angular/common/http';
import { inject, Injectable, makeStateKey, PLATFORM_ID } from '@angular/core';
import { WA_LOCATION } from '@ng-web-apis/common';
import { EMPTY, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';

import { environment } from '../../environments/environment';

export const configKey = makeStateKey<AppConfig>('math-config');

export type AppConfig = {
  api: string;
  storageUrl: string;
  server: string;
};

@Injectable()
export class AppConfigService {
  private config?: AppConfig;

  private readonly platformId = inject(PLATFORM_ID);
  private readonly location = inject(WA_LOCATION);
  private readonly http = inject(HttpClient);

  get(): AppConfig {
    if (!this.config) {
      throw new Error('No configuration loaded.');
    }

    return this.config;
  }

  init(cfg: AppConfig): Promise<void> {
    this.config = cfg;

    return firstValueFrom(of(undefined));
  }

  init$(cfg: AppConfig | undefined): Observable<void> {
    if (isPlatformServer(this.platformId)) {
      return of(undefined);
    }

    if (cfg?.api && cfg?.storageUrl) {
      this.config = cfg;

      return of(undefined);
    }

    const configUrl = `${this.location.origin}/assets/${environment.configFile}`;
    const fallbackConfigUrl = `${this.location.origin}/assets/config.json`;

    return this.getConfig$(configUrl).pipe(
      timeout(250),
      catchError(() => {
        return this.getConfig$(fallbackConfigUrl).pipe(
          catchError(() => {
            return EMPTY;
          }),
        );
      }),
    );
  }

  private getConfig$(configUrl: string): Observable<void> {
    return this.http.get(configUrl).pipe(
      tap((r) => {
        this.config = r as AppConfig;
      }),
      map(() => undefined),
      timeout(1000),
    );
  }
}
