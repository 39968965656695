import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SiteSettingsApiClient } from '@math-site/services';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class SiteTitleService {
  private readonly pageTitle$: Subject<string | null> = new BehaviorSubject<string | null>(null);
  private readonly isHomePage$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly description$: Subject<string | null> = new BehaviorSubject<string | null>(null);
  private readonly seoTitle$: Subject<string | null> = new BehaviorSubject<string | null>(null);

  private set pageTitle(val: string | null) {
    this.pageTitle$.next(val);
  }

  private set isHomePage(val: boolean) {
    this.isHomePage$.next(val);
  }

  constructor(
    private readonly titleService: Title,
    private readonly meta: Meta,
    private readonly siteSettingsClient: SiteSettingsApiClient,
  ) {
    this.setTitleUpdater();
  }

  setHomeTitle(): void {
    this.isHomePage = true;
    this.pageTitle = null;
  }

  setTitle(title: string | null): void {
    this.isHomePage = false;
    this.pageTitle = title;
  }

  setSeoTitle(seoTitle: string | null): void {
    this.seoTitle$.next(seoTitle);
  }

  setSeoDescription(description: string | null): void {
    this.description$.next(description);
  }

  private setTitleUpdater(): void {
    combineLatest([
      combineLatest([
        this.siteSettingsClient.getSiteName().pipe(shareReplay(1)),
        this.siteSettingsClient.getDefaultHomePageTitle().pipe(shareReplay(1)),
        this.siteSettingsClient.getTitleDelimiter().pipe(shareReplay(1)),
      ]),
      this.pageTitle$,
      this.isHomePage$,
      combineLatest([this.description$, this.seoTitle$]),
    ])
      .pipe(
        tap((values) => {
          const [[, defaultHomePageTitle], pageTitle, isHomePage, [seoDescription, seoTitle]] =
            values;

          const title = isHomePage ? defaultHomePageTitle : (seoTitle ?? pageTitle);
          const description = isHomePage
            ? 'Главная страница сайта Математического факультета ЯрГУ'
            : (seoDescription ?? '');

          if (title) {
            this.meta.updateTag({
              name: 'title',
              content: title,
            });
          }

          this.meta.updateTag({
            name: 'description',
            content: description,
          });
        }),
        map((values) => {
          const [[siteName, defaultHomePageTitle, titleDelimiter], pageTitle, isHomePage] = values;

          if (pageTitle) {
            return `${pageTitle}${titleDelimiter}${siteName}`;
          }

          return isHomePage ? `${defaultHomePageTitle}${titleDelimiter}${siteName}` : siteName;
        }),
        tap((value) => this.titleService.setTitle(value)),
        map(() => undefined),
        takeUntilDestroyed(),
      )
      .subscribe();
  }
}
