import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable()
export class RemoteResourcesService {
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(document.body, null);
  }

  appendCssFile(url: string): HTMLLinkElement {
    const element = this.renderer.createElement('link') as HTMLLinkElement;
    element.rel = 'stylesheet';
    element.href = url;

    this.renderer.appendChild(this.document.head, element);

    return element;
  }

  remove(element: HTMLElement): void {
    this.renderer.removeChild(this.document.head, element);
  }

  public appendJsFile(url: string): HTMLScriptElement {
    const element = this.renderer.createElement('script') as HTMLScriptElement;
    element.src = url;

    this.renderer.appendChild(this.document.head, element);

    return element;
  }
}
